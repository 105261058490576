import MarkdownHint from 'components/StaticElements/MarkdownHint';
import MarkdownEditor from '@uiw/react-markdown-editor';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';

const { connect } = require('react-redux');

function deduplicateIgnoreCase(arr) {
    return arr.filter((item, index, self) =>
        self.findIndex((i) => i.toLowerCase() === item.toLowerCase()) === index
    );
}

function mapStateToProps(state) {
    return {
        assignment: state.PEAssignments.selectedAssignment,
        actions: state.PEAssignments.config,
        categoriesOptions: state.PECategories.categoriesOptions,
        selectedRegionFilter: state.user.selectedRegion,
        config: state.admins.config,
    };
}

const PEAssignmentsEdit = connect(mapStateToProps)(function ({
    actions,
    categoriesOptions,
    assignment,
    selectedRegionFilter,
    config,
    onConfirm = function () { },
}) {
    const initialState = {
        name: assignment?.name,
        technical_name: assignment?.technical_name,
        description: assignment?.description,
        category_id: assignment?.category_id,
        region_ident: assignment?.region_ident,
        poster_url: assignment?.poster_url,
        duration_ms: assignment?.duration_ms && parseInt(assignment?.duration_ms / 1000 / 60),
        action: assignment?.action,
        link_text: assignment?.link_text,
        link_url: assignment?.link_url,
        hide_in_languages: assignment?.hide_in_languages,
        card_rules: assignment?.card_rules || {},
    };

    const [updatedAssignment, setUpdatedAssignment] = useState(initialState);
    const [actionOptions, setActionOptions] = useState([]);
    const [cardRulesCheckbox, setCardRulesCheckbox] = useState(!!(assignment?.card_rules && Object.keys(assignment?.card_rules).length > 0));
    const allLanguages = (config?.languages) ? ['all', ...config?.languages] : [];
    const [applyToAll, setApplyToAll] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const allClients = [
        { value: 'is_orange_theory', label: 'Orangetheory' },
        { value: 'is_anytime_fitness', label: 'Anytime Fitness' },
        { value: 'is_gympass', label: 'Gympass' },
    ];

    // ## Function to define if "Apply to All" should be checked or not.
    const defineApplyToAll = (cardRules) => {
        const clientsList = {
            "is_gympass": cardRules.is_gympass,
            "is_orange_theory": cardRules.is_orange_theory,
            "is_anytime_fitness": cardRules.is_anytime_fitness
        }
        let trueCounter = 0;
        Object.values(clientsList).forEach(client => {
            if (client === true) {
                trueCounter++;
            }
        });
        // ## If any of the 3 positions is true, means that "Add Clients" was used, so we set applyToAll to false (unchecked).
        if(trueCounter > 0) {
            setApplyToAll(false);
        }else{
            setApplyToAll(true);
        }
    }

    useEffect(() => {
        if (updatedAssignment?.card_rules) {
            defineApplyToAll(updatedAssignment.card_rules);
        }
        // eslint-disable-next-line
    }, []);

    const handleDropdownClick = (e) => {
        e.preventDefault();
        setIsDropdownOpen(prev => !prev);
    };

    const { PE_UPDATE_ASSIGNMENT_REQUEST, PE_FETCH_ALL_CATEGORIES_REQUEST } = useActions();

    function returnRegionByCategoryId(categoryId) {
        let regionIdent = null;
        if (categoriesOptions && categoriesOptions.length > 0 && categoryId) {
            regionIdent =
                categoriesOptions.find(
                    (cat) => parseInt(cat.value) === parseInt(categoryId)
                )?.region_ident;
        }
        return regionIdent;
    }

    function handleFieldChange(event) {
        let newState = {
            ...updatedAssignment,
            [event.target.name]: event.target.value,
        };
        if (
            event.target.name === 'category_id' &&
            !!event.target.value
        ) {
            newState.region_ident = returnRegionByCategoryId(event.target.value);
        }
        if (
            event.target.name === 'is_orange_theory' ||
            event.target.name === 'is_anytime_fitness' ||
            event.target.name === 'is_gympass'
        ) {
            newState.card_rules = { ...newState.card_rules, [event.target.name]: event.target.checked }
        }
        if (
            event.target.name === 'card_rules'
        ) {
            setCardRulesCheckbox(event.target.checked);
            setApplyToAll(event.target.checked);
            if(event.target.checked){
                newState.card_rules = {};
            }else{
                newState.card_rules = null;
            }
        }
        if (
            event.target.name === 'all_clients'
        ) {

            setApplyToAll(event.target.checked);

            // ## If "Apply to All" is checked, we set all clients to null, so user can specify which clients to exclude (i.e: false, null, null).
            if (event.target.checked) {
                const clients = {};
                allClients.forEach((client) => {
                    clients[client.value] = null;
                });
                newState.card_rules = { ...newState.card_rules, ...clients };
            }else{
                // ## If "Apply to All" is unchecked, we set all clients to false, so user can specify which clients to include (i.e: true. false, false).
                const clients = {};
                allClients.forEach((client) => {
                    clients[client.value] = false;
                });
                newState.card_rules = { ...newState.card_rules, ...clients };
            }
        }
        if (
            (
                event.target.name === 'brand_ident' &&
                !!event.target.value
            ) ||
            event.target.name === 'start_date' ||
            event.target.name === 'stop_date' ||
            event.target.name === 'program_id' ||
            event.target.name === 'include_product_ids' ||
            event.target.name === 'exclude_product_ids' ||
            event.target.name === 'include_statuses' ||
            event.target.name === 'exclude_statuses' ||
            event.target.name === 'exclude_service_levels' ||
            event.target.name === 'exclude_coupon_codes' ||
            event.target.name === 'days_on_program' ||
            event.target.name === 'date_created' ||
            event.target.name === 'service_level' ||
            event.target.name === 'coach_id' ||
            event.target.name === 'coupon_code' ||
            event.target.name === 'private_tags'
        ) {
            
            newState.card_rules = { ...newState.card_rules, [event.target.name]: event.target.value };
        }
        // ## Hide in languages
        if (
            event.target.name === 'hide_in_languages'
        ) {
            const lang = event.target.value;

            if (lang === 'all') {

                if (event.target.checked) {
                    newState.hide_in_languages = allLanguages;
                } else {
                    newState.hide_in_languages = [];
                }

            } else {
                const hideInLanguages = updatedAssignment.hide_in_languages || [];
                if (hideInLanguages.includes(lang)) {
                    newState.hide_in_languages = hideInLanguages.filter((l) => l !== lang);
                } else {
                    newState.hide_in_languages = [...hideInLanguages, lang];
                }
            }

        }
        setUpdatedAssignment(newState);
    }

    function handleMarkdownChange(name, value) {
        const newState = {
            ...updatedAssignment,
            [name]: value,
        };
        setUpdatedAssignment(newState);
    }

    function validateListOfNumbersFormat(value) {
        const regex = /^(\d+)(,\d+)*$/;
        return regex.test(value.replace(/ /g, ''));
    }

    function onSave(event) {
        event.preventDefault();
        try {
            const cleanAssignment = { ...updatedAssignment };
            const selectedOption = actions.find(
                (action) => action.value === updatedAssignment.action
            );
            const options =
                selectedOption && selectedOption.fields
                    ? selectedOption.fields
                    : [];
            const action_obj = {};
            for (let key in updatedAssignment) {
                if (!initialState.hasOwnProperty(key)) {
                    delete cleanAssignment[key];
                }
                if (options?.some((fieldOpt) => fieldOpt.name === key)) {
                    action_obj[key] = updatedAssignment[key];
                }
            }
            if (Object.keys(action_obj).length > 0) {
                cleanAssignment['action_obj'] = action_obj;
            }

            if (cleanAssignment.hide_in_languages?.includes('all')) {
                cleanAssignment.hide_in_languages = ['all'];
            }

            cleanAssignment.id = assignment?.id;
            cleanAssignment.duration_ms = cleanAssignment.duration_ms ? parseInt(cleanAssignment.duration_ms * 1000 * 60) : null;
            cleanAssignment.card_rules = cardRulesCheckbox ? cleanAssignment.card_rules : null;
            if (cleanAssignment.card_rules) {
                if (cleanAssignment.card_rules?.start_date > cleanAssignment.card_rules?.stop_date) {
                    alert("Start date cannot be after stop date.");
                    return;
                }
                if (cleanAssignment.card_rules?.include_product_ids && typeof cleanAssignment.card_rules?.include_product_ids == 'string') {
                    const isValid = validateListOfNumbersFormat(cleanAssignment.card_rules?.include_product_ids);
                    if (isValid || cleanAssignment.card_rules?.include_product_ids === '') { // Allow empty string to let the user clear/reset the input
                        cleanAssignment.card_rules.include_product_ids = cleanAssignment.card_rules.include_product_ids.replace(/ /g, '').split(',').map((id) => parseInt(id));
                        cleanAssignment.card_rules.include_product_ids = cleanAssignment.card_rules.include_product_ids.filter((id, index) => cleanAssignment.card_rules.include_product_ids.indexOf(parseInt(id)) === parseInt(index)); // remove duplicates in include_product_ids
                    } else {
                        alert("Product IDs must be a number or a comma separated list of numbers.");
                        return;
                    }
                }
                if (cleanAssignment.card_rules?.exclude_product_ids && typeof cleanAssignment.card_rules?.exclude_product_ids == 'string') {
                    const isValid = validateListOfNumbersFormat(cleanAssignment.card_rules?.exclude_product_ids);
                    if (isValid || cleanAssignment.card_rules?.exclude_product_ids === '') { // Allow empty string to let the user clear/reset the input
                        cleanAssignment.card_rules.exclude_product_ids = cleanAssignment.card_rules.exclude_product_ids.replace(/ /g, '').split(',').map((id) => parseInt(id));
                        cleanAssignment.card_rules.exclude_product_ids = cleanAssignment.card_rules.exclude_product_ids.filter((id, index) => cleanAssignment.card_rules.exclude_product_ids.indexOf(parseInt(id)) === parseInt(index)); // remove duplicates in exclude_product_ids
                    } else {
                        alert("Product IDs must be a number or a comma separated list of numbers.");
                        return;
                    }
                }
                // If any include_product_ids are in the exclude_product_ids, throw an error
                if (cleanAssignment.card_rules?.include_product_ids && cleanAssignment.card_rules?.exclude_product_ids) {
                    if (typeof cleanAssignment.card_rules?.include_product_ids === 'string') {
                        cleanAssignment.card_rules.include_product_ids = [cleanAssignment.card_rules.include_product_ids];
                    }
                    for (let i = 0; i < cleanAssignment.card_rules.include_product_ids.length; i++) {
                        let productId = cleanAssignment.card_rules.include_product_ids[i];
                        if (cleanAssignment.card_rules.exclude_product_ids.includes(productId)) {
                            alert("Product ID cannot be in both include and exclude lists.");
                            throw new Error("Invalid configuration.");
                        }
                    }
                }
                if (cleanAssignment.card_rules?.service_level && typeof cleanAssignment.card_rules?.service_level == 'string') {
                    cleanAssignment.card_rules.service_level = cleanAssignment.card_rules.service_level.replace(/ /g, '').split(',').map((serviceLevelItem) => serviceLevelItem.trim());
                    cleanAssignment.card_rules.service_level = deduplicateIgnoreCase(cleanAssignment.card_rules.service_level); // remove duplicates in service_level
                }
                if (cleanAssignment.card_rules?.exclude_service_levels && typeof cleanAssignment.card_rules?.exclude_service_levels == 'string') {
                    cleanAssignment.card_rules.exclude_service_levels = cleanAssignment.card_rules.exclude_service_levels.replace(/ /g, '').split(',').map((status) => status.trim());
                    cleanAssignment.card_rules.exclude_service_levels = deduplicateIgnoreCase(cleanAssignment.card_rules.exclude_service_levels); // remove duplicates in exclude_service_levels
                }
                if (cleanAssignment?.card_rules?.service_level && cleanAssignment?.card_rules?.service_level?.some(serviceLevelItem => isNaN(serviceLevelItem))) {
                    alert("Service levels must be a number or a comma separated list of numbers.");
                    throw new Error("Non-numeric values found in service levels.");
                }
                if (cleanAssignment?.card_rules?.exclude_service_levels && cleanAssignment?.card_rules?.exclude_service_levels?.some(serviceLevelItem => isNaN(serviceLevelItem))) {
                    alert("Exclude Service levels must be a number or a comma separated list of numbers.");
                    throw new Error("Non-numeric values found in Exclude service levels.");
                }
                if (cleanAssignment.card_rules?.service_level && cleanAssignment.card_rules?.exclude_service_levels) {
                    if (typeof cleanAssignment.card_rules?.service_level === 'string') {
                        cleanAssignment.card_rules.service_level = [cleanAssignment.card_rules.service_level];
                    }
                    for (let i = 0; i < cleanAssignment.card_rules.service_level.length; i++) {
                        let sl = cleanAssignment.card_rules.service_level[i];
                        if (cleanAssignment.card_rules.exclude_service_levels.includes(sl)) {
                            alert("Service level cannot be in both include and exclude lists.");
                            throw new Error("Invalid configuration.");
                        }
                    }
                }

                if (cleanAssignment.card_rules?.coupon_code && typeof cleanAssignment.card_rules?.coupon_code == 'string') {
                    cleanAssignment.card_rules.coupon_code = cleanAssignment.card_rules.coupon_code.replace(/ /g, '').split(',').map((coupon) => coupon.trim());
                    cleanAssignment.card_rules.coupon_code = deduplicateIgnoreCase(cleanAssignment.card_rules.coupon_code); // remove duplicates in coupon_code
                }
                if (cleanAssignment.card_rules?.include_statuses && typeof cleanAssignment.card_rules?.include_statuses == 'string') {
                    cleanAssignment.card_rules.include_statuses = cleanAssignment.card_rules.include_statuses.replace(/ /g, '').split(',').map((status) => status.trim());
                    cleanAssignment.card_rules.include_statuses = deduplicateIgnoreCase(cleanAssignment.card_rules.include_statuses); // remove duplicates in include_statuses
                }
                if (cleanAssignment.card_rules?.exclude_statuses && typeof cleanAssignment.card_rules?.exclude_statuses == 'string') {
                    cleanAssignment.card_rules.exclude_statuses = cleanAssignment.card_rules.exclude_statuses.replace(/ /g, '').split(',').map((status) => status.trim());
                    cleanAssignment.card_rules.exclude_statuses = deduplicateIgnoreCase(cleanAssignment.card_rules.exclude_statuses); // remove duplicates in exclude_statuses
                }
                if (cleanAssignment.card_rules?.include_statuses && cleanAssignment.card_rules?.exclude_statuses) {
                    if (
                        typeof cleanAssignment.card_rules?.include_statuses ===
                        'string'
                    ) {
                        cleanAssignment.card_rules.include_statuses = [
                            cleanAssignment.card_rules.include_statuses,
                        ];
                    }
                    for (

                        let i = 0;
                        i < cleanAssignment.card_rules.include_statuses.length;
                        i++
                    ) {
                        let statusItem =
                            cleanAssignment.card_rules.include_statuses[i];
                        if (
                            cleanAssignment.card_rules.exclude_statuses.includes(
                                statusItem
                            )
                        ) {
                            alert(
                                'Status cannot be in both include and exclude lists.'
                            );
                            throw new Error('Invalid configuration.');
                        }
                    }
                }

                if (cleanAssignment.card_rules?.exclude_coupon_codes && typeof cleanAssignment.card_rules?.exclude_coupon_codes == 'string') {
                    cleanAssignment.card_rules.exclude_coupon_codes = cleanAssignment.card_rules.exclude_coupon_codes.replace(/ /g, '').split(',').map((status) => status.trim());
                    cleanAssignment.card_rules.exclude_coupon_codes = deduplicateIgnoreCase(cleanAssignment.card_rules.exclude_coupon_codes); // remove duplicates in exclude_coupon_codes
                }
                if (cleanAssignment.card_rules?.coupon_code && cleanAssignment.card_rules?.exclude_coupon_codes) {
                    if (typeof cleanAssignment.card_rules?.coupon_code === 'string') {
                        cleanAssignment.card_rules.coupon_code = [cleanAssignment.card_rules.coupon_code];
                    }
                    for (let i = 0; i < cleanAssignment.card_rules.coupon_code.length; i++) {
                        let cc = cleanAssignment.card_rules.coupon_code[i];
                        if (cleanAssignment.card_rules.exclude_coupon_codes.includes(cc)) {
                            alert("Coupon code cannot be in both include and exclude lists.");
                            throw new Error("Invalid configuration.");
                        }
                    }
                }
                if (cleanAssignment.card_rules?.private_tags && typeof cleanAssignment.card_rules?.private_tags == 'string') {
                    cleanAssignment.card_rules.private_tags = cleanAssignment.card_rules.private_tags.replace(/ /g, '').split(',').map((status) => status.trim());
                    cleanAssignment.card_rules.private_tags = deduplicateIgnoreCase(cleanAssignment.card_rules.private_tags); // remove duplicates in private_tags
                }
                if (cleanAssignment.card_rules?.date_created > new Date().toISOString().split('T')[0]) {
                    alert("User join date cannot be in the future.");
                    return;
                }
                if (cleanAssignment.card_rules?.coupon_code && typeof cleanAssignment.card_rules?.coupon_code == 'string') {
                    cleanAssignment.card_rules.coupon_code = cleanAssignment.card_rules.coupon_code.replace(/ /g, '').split(',').map((status) => status.trim());
                    cleanAssignment.card_rules.coupon_code = cleanAssignment.card_rules.coupon_code.filter((status, index) => cleanAssignment.card_rules.coupon_code.indexOf(status.toLowerCase()) === index); // remove duplicates in coupon_code
                }

                // ## Remove clients that are null (API understands null as false, and for the business rule, clients null (unset) or false are different).
                for (let key in allClients) {
                    if (cleanAssignment.card_rules[allClients[key].value] === null) {
                        delete cleanAssignment.card_rules[allClients[key].value];
                    } 
                }
    
                // ## If applyToAll is false, at least one client must be true.
                if (!applyToAll) {
                    let trueCounter = 0;
                    for (let key in allClients) {
                        if (cleanAssignment.card_rules[allClients[key].value] === true) {
                            trueCounter++;
                        }
                    }
                    if (trueCounter === 0) {
                        alert("If Apply to All Clients is unchecked, at least one client must be selected.");
                        return;
                    }
                }

            }
            
            PE_UPDATE_ASSIGNMENT_REQUEST(cleanAssignment);
            onConfirm();
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (updatedAssignment.action) {
            const selectedOption = actions.find(
                (action) => action.value === updatedAssignment.action
            );
            if (selectedOption) {
                const { fields } = selectedOption;
                setActionOptions(fields);
            } else {
                setActionOptions([]);
            }
        } else {
            setActionOptions([]);
        }
    }, [actions, updatedAssignment.action, updatedAssignment.hide_in_languages]);

    useEffect(() => {
        if (assignment) {
            const assign = { ...assignment, ...assignment.action_obj, duration_ms: assignment.duration_ms / 1000 / 60 };
            setUpdatedAssignment(assign);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        PE_FETCH_ALL_CATEGORIES_REQUEST();
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    useEffect(() => {
        if (categoriesOptions && categoriesOptions.length > 0) {
            if (assignment) {
                const remainingRegion = returnRegionByCategoryId(assignment.category_id);
                let selectedCategory = (assignment.category_id) ? assignment.category_id : categoriesOptions[0].value;
                let selectedCategoryRegion = (assignment.category_id) ? assignment.region_ident : categoriesOptions[0].region_ident;
                let assign = { ...assignment, category_id: selectedCategory, region_ident: selectedCategoryRegion };
                if (remainingRegion) {
                    assign = { ...assign, region_ident: remainingRegion };
                }
                setUpdatedAssignment(assign);
            }
        }
        // eslint-disable-next-line
    }, [categoriesOptions]);

    return (
        <section>
            <form onSubmit={onSave}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={updatedAssignment.name}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold">Technical Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="technical_name"
                                    value={updatedAssignment.technical_name}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Description
                            </td>
                            <td>

                                <MarkdownEditor
                                    name="description"
                                    value={updatedAssignment.description}
                                    onChange={(value) => handleMarkdownChange('description', value)}
                                    toolbars={['bold', 'italic', 'underline', 'header', 'olist', 'ulist']}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Category</td>
                            <td>
                                <select
                                    className="w-full"
                                    name="category_id"
                                    value={updatedAssignment.category_id}
                                    onChange={handleFieldChange}
                                >
                                    {categoriesOptions?.map((category) => (
                                        <option
                                            key={`category-option-${category.value}`}
                                            value={category.value}
                                        >
                                            {category.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <span className='text-gray'>{updatedAssignment?.region_ident}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Poster URL</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="poster_url"
                                    value={updatedAssignment.poster_url}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Link Description</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="link_text"
                                    value={updatedAssignment.link_text}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Link URL</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="link_url"
                                    value={updatedAssignment.link_url}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Duration in Minutes</td>
                            <td>
                                <input
                                    name="duration_ms"
                                    value={updatedAssignment.duration_ms}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Action</td>
                            <td>
                                <select
                                    className="w-full"
                                    name="action"
                                    value={updatedAssignment.action}
                                    onChange={handleFieldChange}
                                >
                                    {actions?.map((action) => (
                                        <option
                                            key={`action-option-${action.value}`}
                                            value={action.value}
                                        >
                                            {action.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        {actionOptions?.map((actOpt) => (
                            <tr key={`act-opt-${actOpt.name}`}>
                                <td className="font-bold label">
                                    {actOpt.label}
                                </td>
                                <td>
                                    <input
                                        name={actOpt.name}
                                        value={updatedAssignment[actOpt.name]}
                                        onChange={handleFieldChange}
                                    />
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className="font-bold label">
                                <span className='block mb-2'>Hidden In Languages</span>

                            </td>
                            <td>
                                {allLanguages.map((lang) => (
                                    <div key={`div-lang-${lang}`}>
                                        <input
                                            type='checkbox'
                                            name='hide_in_languages'
                                            className='inline hide_in_languages-checkbox'
                                            key={`lang-${lang}`}
                                            value={lang}
                                            checked={updatedAssignment.hide_in_languages?.includes(lang) || updatedAssignment.hide_in_languages?.includes('all')}
                                            id={`lang-${lang}`}
                                            onChange={handleFieldChange}
                                            disabled={
                                                lang !== 'all' &&
                                                updatedAssignment.hide_in_languages?.includes(
                                                    'all'
                                                )
                                            }
                                        />
                                        <label className='checkbox-label' htmlFor={`lang-${lang}`}>{lang}</label>
                                    </div>
                                ))}
                            </td>
                        </tr>

                        <tr>
                            <td className="font-bold label">
                                <span className='block mb-2'>Card Rules</span>

                            </td>
                            <td className='card-rules'>
                                <input
                                    type='checkbox'
                                    name='card_rules'
                                    className='!w-auto mb-4'
                                    checked={cardRulesCheckbox}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        {cardRulesCheckbox && (
                            <>
                                <tr>
                                    <td className="font-bold label">
                                        <span className='block'>Start Date</span>
                                    </td>
                                    <td>
                                        <input
                                            type='date'
                                            name='start_date'
                                            className='w-full'
                                            value={updatedAssignment.card_rules?.start_date}
                                            onChange={handleFieldChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">
                                        <span className='block'>Stop Date</span>
                                    </td>
                                    <td>
                                        <input
                                            type='date'
                                            name='stop_date'
                                            className='w-full'
                                            value={updatedAssignment.card_rules?.stop_date}
                                            onChange={handleFieldChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">
                                        <span className='block'>Program ID (display)</span>
                                    </td>
                                    <td>
                                        <input
                                            type='number'
                                            className='w-full'
                                            name="program_id"
                                            value={updatedAssignment.card_rules?.program_id}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Include Product IDs</td>
                                    <td>
                                        <input
                                            className='w-full'
                                            name="include_product_ids"
                                            value={updatedAssignment.card_rules?.include_product_ids}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Exclude Product IDs</td>
                                    <td>
                                        <input
                                            className='w-full'
                                            name="exclude_product_ids"
                                            value={updatedAssignment.card_rules?.exclude_product_ids}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Service Levels</td>
                                    <td>
                                        <input
                                            className='w-full'
                                            name="service_level"
                                            value={updatedAssignment.card_rules?.service_level}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Exclude Service Levels</td>
                                    <td>
                                        <input
                                            className='w-full'
                                            name="exclude_service_levels"
                                            value={updatedAssignment.card_rules?.exclude_service_levels}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Include Statuses</td>
                                    <td>
                                        <input
                                            className='w-full'
                                            name="include_statuses"
                                            value={updatedAssignment.card_rules?.include_statuses}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Exclude Statuses</td>
                                    <td>
                                        <input
                                            className='w-full'
                                            name="exclude_statuses"
                                            value={updatedAssignment.card_rules?.exclude_statuses}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Apply to All Clients</td>
                                    <td>
                                        <input
                                            type='checkbox'
                                            name='all_clients'
                                            className='!w-auto'
                                            checked={applyToAll}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                { applyToAll && (
                                    <tr>
                                        <td className="font-bold label">Exclude Clients</td>
                                        <td>
                                            <div className="relative">
                                                <div className="flex items-center">
                                                    <button
                                                        className="px-4 py-2 mr-3 text-left border rounded-md bg-white"
                                                        onClick={handleDropdownClick}
                                                    >
                                                        Select Clients
                                                    </button>
                                                    <div>
                                                        {
                                                            allClients.map((client) => (
                                                                updatedAssignment?.card_rules?.[client.value] === false ? client.label : ''
                                                            )).filter(label => label !== '').join(', ')
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${isDropdownOpen ? '' : 'hidden'} absolute w-48 z-10 bg-white border rounded-md shadow-lg`}
                                                >
                                                    <div className="p-4 space-y-2">
                                                        {allClients.map(client => (
                                                            <div key={client.value} className="flex items-center space-x-2">
                                                                <label htmlFor={`client-${client.value}`} className="text-sm w-8/12">
                                                                    {client.label}
                                                                </label>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`client-${client.value}`}
                                                                    checked={updatedAssignment?.card_rules?.[client.value] === false}
                                                                    onChange={(e) => {
                                                                        setUpdatedAssignment(prevAssignment => ({
                                                                            ...prevAssignment,
                                                                            card_rules: {
                                                                                ...prevAssignment.card_rules,
                                                                                [client.value]: e.target.checked ? false : null
                                                                            }
                                                                        }))
                                                                    }}
                                                                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 w-auto"
                                                                />

                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                { !applyToAll && (
                                    <tr>
                                        <td className="font-bold label">Add Clients</td>
                                        <td className="relative">
                                            <div className="relative">
                                                <div className="flex items-center">
                                                    <button
                                                        className="px-4 py-2 mr-3 text-left border rounded-md bg-white"
                                                        onClick={handleDropdownClick}
                                                    >
                                                        Select Clients
                                                    </button>
                                                    <div>
                                                        {
                                                            allClients.map((client) => (
                                                                updatedAssignment?.card_rules?.[client.value] === true ? client.label : ''
                                                            )).filter(label => label !== '').join(', ')
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${isDropdownOpen ? '' : 'hidden'} absolute w-48 z-10 bg-white border rounded-md shadow-lg`}
                                                >
                                                    <div className="p-4 space-y-2">
                                                        {allClients.map(client => (
                                                            <div key={client.value} className="flex items-center space-x-2">
                                                                <label htmlFor={`client-${client.value}`} className="text-sm w-8/12">
                                                                    {client.label}
                                                                </label>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`client-${client.value}`}
                                                                    checked={updatedAssignment?.card_rules?.[client.value] === true}
                                                                    onChange={(e) => {
                                                                        setUpdatedAssignment(prevAssignment => ({
                                                                            ...prevAssignment,
                                                                            card_rules: {
                                                                                ...prevAssignment.card_rules,
                                                                                [client.value]: e.target.checked ? true : false
                                                                            }
                                                                        }))
                                                                    }}
                                                                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500 w-auto"
                                                                />

                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="font-bold label">Days on Program</td>
                                    <td>
                                        <input
                                            type='number'
                                            className='w-full'
                                            name="days_on_program"
                                            value={updatedAssignment.card_rules?.days_on_program}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">User Join Date</td>
                                    <td>
                                        <input
                                            type='date'
                                            name='date_created'
                                            className='w-full'
                                            value={updatedAssignment.card_rules?.date_created}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Coach ID</td>
                                    <td>
                                        <input
                                            type='number'
                                            className='w-full'
                                            name="coach_id"
                                            value={updatedAssignment.card_rules?.coach_id}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Coupon Codes</td>
                                    <td>
                                        <input
                                            className='w-full'
                                            name="coupon_code"
                                            value={updatedAssignment.card_rules?.coupon_code}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Exclude Coupon Codes</td>
                                    <td>
                                        <input
                                            className='w-full'
                                            name="exclude_coupon_codes"
                                            value={updatedAssignment.card_rules?.exclude_coupon_codes}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-bold label">Private Tags</td>
                                    <td>
                                        <input
                                            className='w-full'
                                            name="private_tags"
                                            value={updatedAssignment.card_rules?.private_tags}
                                            onChange={handleFieldChange}
                                        />
                                    </td>
                                </tr>
                            </>
                        )}

                        {initialState !== updatedAssignment && (
                            <tr>
                                <td colSpan={2}>
                                    <button type="submit" className="mt-4 button save-changes-button">
                                        Save
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default PEAssignmentsEdit;
